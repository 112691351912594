import { css } from "@emotion/css";

interface IBoxProps {
  borderStyle?: string;
  disabled?: boolean;
  flex?: string;
  justifyContent?: string;
  onClick?(): void;
  rotate?: number;
}

export const Box: React.FC<IBoxProps> = ({
  borderStyle,
  children,
  disabled,
  flex,
  justifyContent,
  onClick,
  rotate,
}) => {
  return (
    <div
      className={css`
        border-color: ${disabled ? "grey" : "black"};
        border-style: ${borderStyle};
        color: ${disabled ? "grey" : ""};
        cursor: default;
        display: flex;
        flex: ${flex};
        justify-content: ${justifyContent};
        padding: 5px;
        transform: rotate(${rotate}deg);
      `}
      onClick={() => !disabled && onClick && onClick()}
    >
      {children}
    </div>
  );
};
