import { Box } from "./box";

export const Button: React.FC<{
  title: string;
  onClick(): void;
  disabled?: boolean;
}> = ({ title, onClick, disabled = false }) => {
  return (
    <Box borderStyle="solid" disabled={disabled} onClick={onClick}>
      {title}
    </Box>
  );
};
