import React, { useState } from "react";
import "./App.css";
import { Box } from "./box";
import { DoublingButton } from "./doublingButton";
import { Number } from "./number";
import { Stack } from "./stack";

function App() {
  const [position, setPosition] = useState<"top" | "center" | "bottom">(
    "center"
  );
  const [number, setNumber] = useState(1);
  const doubleNumber = () =>
    setNumber((number) => (number < 64 ? number * 2 : number));
  return (
    <div className="App">
      <Stack>
        <Box rotate={180}>
          <DoublingButton
            onClick={() => {
              doubleNumber();
              setPosition("bottom");
            }}
            disabled={position === "bottom" || number >= 64}
          />
        </Box>
        <Box flex="1">
          <Stack
            justifyContent={
              position === "top"
                ? "flex-start"
                : position === "bottom"
                ? "flex-end"
                : "center"
            }
          >
            <Box rotate={270}>
              <Number number={number} />
            </Box>
          </Stack>
        </Box>
        <DoublingButton
          onClick={() => {
            doubleNumber();
            setPosition("top");
          }}
          disabled={position === "top" || number >= 64}
        />
      </Stack>
    </div>
  );
}

export default App;
