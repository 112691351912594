import { css } from "@emotion/css";

export const Stack: React.FC<{ justifyContent?: string }> = ({
  children,
  justifyContent,
}) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: ${justifyContent};
        align-items: center;
      `}
    >
      {children}
    </div>
  );
};
