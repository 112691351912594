import { css } from "@emotion/css";

export const Number: React.FC<{ number: number }> = ({ number }) => {
  return (
    <span
      className={css`
        font-size: 60px;
      `}
    >
      {number}
    </span>
  );
};
