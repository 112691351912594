import { Box } from "./box";
import { Button } from "./button";

export const DoublingButton: React.FC<{ onClick(): void; disabled?: boolean }> =
  ({ onClick, disabled = false }) => {
    return (
      <Box>
        <Button title="Dubblera" onClick={onClick} disabled={disabled} />
      </Box>
    );
  };
